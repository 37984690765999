import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 9

export const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.training?.isAccess
}

export const guardTrainingAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}

export const guardTrainingCatalog = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.training?.catalogAccess ? user.profile.modules.training.catalogAccess : { name: 'Home' }
}

export const guardMyTraining = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.training?.myTrainingsAccess ? user.profile.modules.training.myTrainingsAccess : { name: 'Home' }
}
