<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useElementBounding } from '@vueuse/core'
import { vOnClickOutside } from '@vueuse/components'
import BProgress from './BProgress.vue'
import BLabel from '@/components/common/bLabel.vue'
import { bgColors } from '@/services/application/constantes'
import { size as sizeConst } from '@/services/application/constantes'

type IOption = {
  _id: string | number
  display: string
  value?: boolean
}

const props = withDefaults(defineProps<{ 
  disabled?: boolean; 
  rounded?: string; 
  popoverContainer?: any; 
  label?: string; 
  bg?: keyof typeof bgColors; 
  options: IOption[]; 
  tabindex?: number; 
  modelValue?: IOption | string | number; 
  forceId?: boolean; 
  loading?: boolean; 
  size?: keyof typeof sizeConst; 
}>(), {
  options: [],
  tabindex: 0,
  bg: 'base',
  rounded: 'rounded-lg',
  disabled: false,
  size: 'small',
  loading: false,
  forceId: false,
  label: '',
  popoverContainer: 'body',
})
const emit = defineEmits(['selected', 'update:modelValue'])

const oontainerRef = ref()
const { width } = useElementBounding(oontainerRef)

const textSize = {
  mini: 'text-xs',
  normal: 'text-base',
}
const labelSize = {
  mini: 'mini',
  normal: 'normal',
}

const open = ref(false)

const openSelect = () => {
  if (props.disabled)
    return
  open.value = !open.value
}
// const options = toRef(props, 'options')
const selectItem = (option: IOption) => {
  open.value = false
  emit('selected', option)
  const updateToSend = typeof props.modelValue === 'string' || typeof props.modelValue === 'number' || props.forceId ? option._id : option
  emit('update:modelValue', updateToSend)
}

const optionsNotSelected = computed(() => props.options.filter(option => !props.selected || option._id !== props.selected._id))
const optionSelected = computed(() => {
  if (!props.modelValue && props.modelValue !== 0)
    return null
  const toSearch = typeof props.modelValue === 'string' || typeof props.modelValue === 'number' ? props.modelValue : props.modelValue._id

  return props.options.find(option => option._id === toSearch)
})
</script>

<template>
  <div ref="oontainerRef" class="flex flex-col relative" :class="{'mt-5': label}" :tabindex="tabindex">
    <slot name="label">
      <p v-if="label" class="subtitle absolute -top-2 left-0 transform -translate-y-1/2" :class="sizeConst[size]">
        {{ label }}
      </p>
    </slot>
    <div
      class="selected custom-select flex border border-base pl-3 pr-5 relative items-center"
      :class="[bgColors[bg], sizeConst[size],{ 'open': open, 'pointer-events-none opacity-50': options.length < 1, 'opacity-50 !cursor-not-allowed': disabled }, rounded]"
      @click="openSelect"
    >
      <slot v-if="optionSelected" name="selected" :selected="optionSelected">
        <p class="line-clamp-1">
          {{ optionSelected.display }}
        </p>
      </slot>
      <slot v-else-if="options.length < 1" name="noData">
        <p class="line-clamp-1">
          {{ $t('common.selectNoData') }}
        </p>
      </slot>
      <slot v-else name="placeholder">
        <p class="line-clamp-1">
          {{ $t('common.selectElement') }}
        </p>
      </slot>
    </div>
    <VDropdown :container="popoverContainer" :triggers="[]" :shown="open" :auto-hide="false" placement="bottom">
      <template #popper>
        <div
          v-on-click-outside="() => open = false" :style="{ width: `${width}px` }"
          class="border border-base max-h-56 overflow-x-hidden overflow-y-auto rounded-xl border-base color-base text-base transition-all mini-scroll"
          :class="[bgColors[bg]]"
        >
          <div
            v-for="(option, i) of optionsNotSelected" :key="option._id || i"
            class="item px-2 py-2 cursor-pointer select-none hover:bg-fade" @click="selectItem(option)"
          >
            <slot name="option" :option="option">
              {{ option?.display || option }}
            </slot>
          </div>
        </div>
      </template>
    </VDropdown>
    <BProgress v-show="loading" color="bg-primary-400" bg="bg-primary-100" height="h-1" :loading="loading" />
  </div>
</template>

<style>
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
  /*line-height: 47px;*/
}

.selected {
  cursor: pointer;
  user-select: none;
}

/* .selected.open {
  border-radius: 6px 6px 0px 0px;
} */

.selected:after {
  position: absolute;
  content: "";
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: #000 transparent transparent transparent;
}

.items {
  border-radius: 0px 0px 6px 6px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0, 100%, 0);
}

.items .item {
  cursor: pointer;
  user-select: none;
}

.v-popper__arrow-container {
  display: none;
}
</style>
