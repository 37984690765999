<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useDark } from '@vueuse/core'; // Keep simple hooks locally if desired
import ThemeSwitcher from '@/components/common/ThemeSwitcher.vue';
import LocalSwitch from '@/components/common/LocalSwitch.vue';
import MaterialSymbolsLockOutline from '~icons/material-symbols/lock-outline';
import MaterialSymbolsLockOpenOutlineRounded from '~icons/material-symbols/lock-open-outline-rounded';
import PhSunDuotone from '~icons/ph/sun-duotone';
import PhMoonStarsDuotone from '~icons/ph/moon-stars-duotone';
import BcPictograme from '@/assets/images/BestConsultant_Pictogramme.svg?url';

const props = defineProps({
  appVersion: {
    type: String,
    required: true,
  },
  user: { // Needed for logo logic
    type: Object,
    required: true,
  },
   isFixed: {
    type: Boolean,
    required: true,
  },
  isHovering: { // Is the parent drawer being hovered (when not fixed)?
    type: Boolean,
    required: true,
  },
   // Function passed down
  toggleFixedFn: {
      type: Function as PropType<() => void>,
      required: true
  }
});

const isDark = useDark(); // Okay to keep simple hook usage here

const firmLogo = computed(() => {
    return props.user?.firm?.isWL && props.user.firm.picture ? props.user.firm.picture : BcPictograme
});

const firmName = computed(() => props.user?.firm?.company || 'Company');

// Determines if the controls should be shown based on fixed/hover state
const showControls = computed(() => props.isFixed || props.isHovering);

</script>

<template>
  <div class="mb-4 mt-auto px-auto py-3 text-center text-xs whitespace-nowrap flex flex-col items-center gap-3 shrink-0">
     <!-- Mobile Controls (Always visible on mobile, hidden on desktop) -->
    <div class="flex md:hidden gap-4 text-xl items-center h-7">
       <LocalSwitch />
       <ThemeSwitcher>
           <template #default="{ isDark: isDarkTheme, toggle }">
              <span class="tracking-wide cursor-pointer text-xl" @click="toggle">
                <PhMoonStarsDuotone v-if="isDarkTheme.value" />
                <PhSunDuotone v-else />
              </span>
           </template>
       </ThemeSwitcher>
    </div>

    <!-- Desktop Lock Control (Only visible on desktop, appears on hover/fixed) -->
    <!-- <div v-show="showControls" class="hidden md:flex items-center justify-center h-7">
       <span
          title="Toggle fixed navigation drawer"
          data-cy="navigation-drawer-lock"
          class="tracking-wide cursor-pointer text-xl hover:text-primary-500 transition-colors"
          @click="toggleFixedFn"
        >
          <MaterialSymbolsLockOpenOutlineRounded v-if="isFixed" />
          <MaterialSymbolsLockOutline v-else />
        </span>
    </div> -->

    <!-- Logo and Version -->
    <div class="flex flex-col items-center gap-2">
       <router-link to="/" class="transition-transform duration-300" :class="{ 'hover:rotate-90': !user?.firm?.isWL }">
          <img
            class="h-14 w-auto max-w-full"
            height="56"
            width="56"
            :src="firmLogo"
            :alt="`${firmName} logo`"
          />
       </router-link>
       <span class="text-muted">v{{ appVersion }}</span>
    </div>
     <!-- Optional: <ReloadPrompt /> could go here -->
  </div>
</template>

<style scoped>
/* Styles for showing controls on hover/fixed state */
.drawer-container:hover .hidden.md\:flex,
.drawer-container-fixed .hidden.md\:flex {
  display: flex !important;
   opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .hidden.md\:flex {
     opacity: 0;
     display: none;
   }
}
</style>
