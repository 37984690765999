<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { Ref } from 'vue';
import { computed, inject, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useDark, useElementHover } from '@vueuse/core';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { useUserStore } from '@/config/store/userStore';
import { useApplicationStore } from '@/config/store/applicationStore';
import { getDrawerMenu, getDrawerMenuSuperAdmin } from '@/services/application/constantes';
import { APP_VERSION } from '@/config/constantes';
import { resourceApi } from '@/services/api';
import { guardExport } from '@/config/router/routes';

// Import child components
import DrawerHeader from './DrawerHeader.vue';
import DrawerMenuList from './DrawerMenuList.vue';
import DrawerFooter from './DrawerFooter.vue';

// Keep necessary imports for this component
import SearchCommand from '@/components/search/SearchCommand.vue';
import BButton from '@/components/common/bButton.vue';
import Loading from '@/components/common/loading.vue'; // Might be needed if updateProfile has visual cue here
import SolarCloudDownloadOutline from '~icons/solar/cloud-download-outline';
import MaterialSymbolsAdminPanelSettingsOutlineSharp from '~icons/material-symbols/admin-panel-settings-outline-sharp';
import IcRoundQuestionMark from '~icons/ic/round-question-mark';
import SolarDatabaseLineDuotone from '~icons/solar/database-line-duotone';
import MdiExitRun from '~icons/mdi/exit-run'; // Import logout icon

// ----- Core Logic retained from original -----
const emit = defineEmits<{
  'change-password': []
}>();

const { t } = useI18n();
const route = useRoute();
const userStore = useUserStore();
const applicationStore = useApplicationStore();
const isDark = useDark(); // Keep for top-level theme logic if needed, or remove if only in footer

// PWA Logic
const { needRefresh, updateServiceWorker } = useRegisterSW();

// State
const drawerRef = ref<HTMLElement | null>(null);
const isHovering = useElementHover(drawerRef);
const isOpen = ref(false); // Mobile open state
const isUpdatingProfile = ref(false); // State for profile update loading
const imageError = ref(false); // Kept separate from header for now, could be prop if needed there
const isDropDownOpen = ref(false); // State for the header dropdown

// Scroll lock injection
const scrollBlocked = inject<Ref<boolean>>('scrollBlocked');
watch(isOpen, (newValue) => {
  if (scrollBlocked) {
    scrollBlocked.value = newValue;
  }
});

// Computed Properties
const isSuperAdminRoute = computed(() => {
  return !!route.matched.find(currentRoute => currentRoute.meta.moduleId === 'SUPER_ADMIN');
});

const drawerColor = computed(() => {
  return isSuperAdminRoute.value ? 'bg-base' : 'bg-base'; // Adjust colors if needed
});

const availableProfiles = computed(() => {
    if (!userStore.user) return [];
    // Ensure current profile is not duplicated if already in availableProfiles
    const currentProf = userStore.user.profile;
    const otherProfiles = userStore.user.availableProfiles.filter(p => p._id !== currentProf._id);
    return [...otherProfiles, currentProf]; // Place current profile last or first as desired
});

// Use the store state directly for fixed status
const isFixed = computed(() => applicationStore.drawer.fixed);

// Menu Items Construction (kept inside)
const menuItems = computed(() => {
    const baseItems = isSuperAdminRoute.value
        ? getDrawerMenuSuperAdmin()
        : getDrawerMenu();

    const specialItems = [];

    if (!isSuperAdminRoute.value && userStore.user) { // Check user exists
        let needsSeparator = false;
        if (guardExport() === true) {
            specialItems.push({
                display: 'common.drawer.export', route: 'Export',
                prependIcon: SolarDatabaseLineDuotone, moduleId: 'export',
                color: 'hover:!border-primary-800 hover:!bg-primary-800/25',
            });
            needsSeparator = true;
        }
        if (userStore.user.profile?.isSubAdminProfile) {
             specialItems.push({
                display: 'common.drawer.superAdmin', route: 'SuperAdminUsers',
                prependIcon: MaterialSymbolsAdminPanelSettingsOutlineSharp, moduleId: 'sub-admin',
                color: 'hover:!border-warning-800 hover:!bg-warning-800/25',
             });
             needsSeparator = true;
        }

        if (needsSeparator && specialItems.length > 0) {
             specialItems.unshift({ separator: true });
        }

        // Add Documentation and Logout after a potential second separator
         specialItems.push({ separator: true }); // Separator before docs/logout
         specialItems.push({
             display: 'common.drawer.documentation', route: 'DocumentationHome',
             prependIcon: IcRoundQuestionMark, moduleId: 'docs',
             color: 'hover:!border-primary-800 hover:!bg-primary-800/25',
         });
         specialItems.push({
            display: 'common.drawer.logOut', route: 'Disconnection', // Use route directly
            prependIcon: MdiExitRun, moduleId: 'logout',
            color: 'hover:!border-red-800 hover:!bg-red-800/25',
         });
    }
    return [...baseItems, ...specialItems];
});


// --- Methods ---
const toggleMenuMobile = (forceState?: boolean) => {
  isOpen.value = typeof forceState === 'boolean' ? forceState : !isOpen.value;
};

const closeMobileMenu = () => {
    toggleMenuMobile(false);
}

const updateAccessProfile = async (profile) => {
  if (!userStore.user) return;
  isUpdatingProfile.value = true;
  try {
    const currentProfileId = userStore.user.profile._id;
    const newAvailableProfileIds = [
      ...userStore.user.availableProfileIds.filter(id => id !== profile._id),
      currentProfileId
    ];
    await resourceApi.updateResource(userStore.user._id, {
      accessProfile: profile._id,
      availableProfileIds: newAvailableProfileIds,
    }).promise;
    // Update store
     userStore.switchUserProfile(profile); // Assumes store handles internal updates
  } catch (error) {
    console.error("Failed to update profile:", error);
    // Maybe use applicationStore.addError(...)
  } finally {
    isUpdatingProfile.value = false;
  }
};

const toggleFixedState = () => {
  applicationStore.drawer.fixed = !applicationStore.drawer.fixed;
};

const emitChangePassword = () => {
    emit('change-password');
}

</script>

<template>
  <div
    v-if="userStore.user"
    ref="drawerRef"
    data-cy="navigation-drawer"
    class="relative mx-2 mt-2 md:mx-0 md:mt-0 md:bottom-2 md:left-2 md:top-2 md:fixed transition-all duration-300 z-50 flex flex-col md:flex-grow card-base p-0 h-16 md:h-auto overflow-hidden shrink-0"
    :class="[
        drawerColor,
        isFixed ? 'md:w-64 drawer-container-fixed' : 'md:w-14 drawer-container shadow',
        { 'md:hover:w-64': !isFixed },
        { 'on-mobile-disable-rounded-bottom h-[calc(100dvh-2rem)] overflow-y-auto': isOpen },
        // Add overflow classes based on mobile state
    ]"
  >

    <!-- Header Section -->
    <DrawerHeader
      :user="userStore.user"
      :available-profiles="availableProfiles"
      :current-profile="userStore.user.profile"
      :is-updating-profile="isUpdatingProfile"
      :is-fixed="isFixed"
      :is-open="isOpen"
      :is-hovering="isHovering && !isFixed"
      :drawer-ref="drawerRef"
      :update-profile-fn="updateAccessProfile"
      :toggle-mobile-menu-fn="toggleMenuMobile"
      :toggle-fixed-fn="toggleFixedState"
      :emit-change-password-fn="emitChangePassword"
    />

    <!-- PWA Update Button -->
     <BButton
        v-if="needRefresh"
        bg="success"
        class="mx-2 shrink-0 update-button"
        @click="updateServiceWorker"
     >
      <SolarCloudDownloadOutline class="animate-bounce h-5 w-5 shrink-0" />
      <span class="update-button-text whitespace-nowrap overflow-hidden">
         Update available
      </span>
     </BButton>

    <!-- Search Command -->
    <SearchCommand
        v-if="userStore.user"
        class="mx-2 shrink-0 search-command"
        :class="{ 'search-command-hidden': !isFixed && !isHovering && !isOpen }"
     />

    <!-- Menu List Section -->
    <!-- Conditional rendering/styling for mobile menu panel -->
     <div
        class="transition-all duration-200 mini-scroll md:relative grow h-auto flex-grow"
        :class="[
           drawerColor,
            // isOpen
            //     ? 'h-[calc(100%-180px)] fixed bottom-2 left-2 right-2 rounded-b-xl overflow-y-auto z-10' // Mobile open style: Take remaining height, fixed position
            //     : '' // Desktop/Mobile closed: Normal flow or hide
        ]"
     >
       <DrawerMenuList
         :menu-items="menuItems"
         :is-super-admin-route="isSuperAdminRoute"
         :close-mobile-menu-fn="closeMobileMenu"
       />
     </div>


    <!-- Footer Section -->
    <DrawerFooter
      :app-version="APP_VERSION"
      :user="userStore.user"
      :is-fixed="isFixed"
      :is-hovering="isHovering && !isFixed"
      :toggle-fixed-fn="toggleFixedState"
    />
  </div>
</template>

<style scoped>
/* Base classes for fixed/hover state */
.drawer-container { /* Base styles for collapsed state */ }
.drawer-container-fixed { /* Styles when drawer is fixed */ }

/* Update Button Text visibility */
.update-button-text { display: none; }
.drawer-container:hover .update-button-text,
.drawer-container-fixed .update-button-text {
  display: inline; animation: fade-in 0.5s forwards;
}
@keyframes fade-in { from { opacity: 0; } to { opacity: 1; } }

/* Search Command visibility */
.search-command { transition: opacity 0.3s, max-width 0.3s; max-width: 500px; opacity: 1; }
.search-command-hidden {
    @media (min-width: 768px) { /* Only hide on desktop when collapsed/not hovered */
        opacity: 0;
        max-width: 0;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
    }
}
.drawer-container:hover .search-command-hidden,
.drawer-container-fixed .search-command-hidden {
    @media (min-width: 768px) {
        opacity: 1;
        max-width: 500px;
        /* Re-apply padding/margin if needed */
    }
}


/* Mobile rounded corners */
.on-mobile-disable-rounded-bottom {
  @media screen and (max-width: 768px) {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

/* Mini-scroll styles */
.mini-scroll { scrollbar-width: thin; scrollbar-color: theme('colors.gray.400') theme('colors.gray.200'); }
.mini-scroll::-webkit-scrollbar { width: 6px; height: 6px; }
.mini-scroll::-webkit-scrollbar-track { background-color: theme('colors.gray.200'); border-radius: 3px; }
.mini-scroll::-webkit-scrollbar-thumb { background-color: theme('colors.gray.400'); border-radius: 3px; }
html.dark .mini-scroll { scrollbar-color: theme('colors.gray.600') theme('colors.gray.800'); }
html.dark .mini-scroll::-webkit-scrollbar-track { background-color: theme('colors.gray.800'); }
html.dark .mini-scroll::-webkit-scrollbar-thumb { background-color: theme('colors.gray.600'); }
</style>
