import { RouteRecordRaw } from "vue-router";
import { guardInvoiceAccess } from "../navigationsGuards/guardInvoice";

const MODULE_NAME = 'INVOICES';
const MODULE_ID = 19;

const evaluationRoutes: Array<RouteRecordRaw> = [
    {
        path: '/invoice',
        meta: {
            requiresAuth: true,
        },
        beforeEnter: [guardInvoiceAccess],
        children: [
            {
                path: '',
                name: 'InvoiceList',
                component: () => import('@/views/invoice/invoiceList.vue'),
                beforeEnter: [],
            },
            {
                path: 'project',
                beforeEnter: [],
                children: [
                    {
                        path: '',
                        name: 'InvoiceProjects',
                        component: () => import('@/views/invoice/Projects.vue'),
                    },
                    {
                        path: ':projectId',
                        name: 'ProjectInvoice',
                        component: () => import('@/views/invoice/ProjectInvoiceDetails.vue'),

                        beforeEnter: [],
                    },
                ]
            },

        ]

    },
]

export default evaluationRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }));
