<script lang="ts" setup>
import { ref, computed, type PropType, type Ref } from 'vue';
import BButton from '@/components/common/bButton.vue';
import BSelect from '@/components/common/bSelect.vue';
import BLabel from '@/components/common/bLabel.vue';
import BDropdown from '@/components/common/bDropdown.vue';
import Loading from '@/components/common/loading.vue';
import Burger from '@/components/navigationDrawer/burger.vue';
import LocalSwitch from '@/components/common/LocalSwitch.vue';
import ThemeSwitcher from '@/components/common/ThemeSwitcher.vue';
import DefaultProfile from '@/assets/svg/profile.svg';
import SolarSettingsBroken from '~icons/solar/settings-broken';
import SolarKeyBroken from '~icons/solar/key-broken';
import MaterialSymbolsLockOutline from '~icons/material-symbols/lock-outline';
import MaterialSymbolsLockOpenOutlineRounded from '~icons/material-symbols/lock-open-outline-rounded';
import PhSunDuotone from '~icons/ph/sun-duotone';
import PhMoonStarsDuotone from '~icons/ph/moon-stars-duotone';

// Define dropdown item structure locally or import if shared
interface DropdownItem {
  display: string | (() => string);
  click?: () => void;
  bgColor?: string;
  _id: string;
  slotName?: string;
}

const props = defineProps({
  user: {
    type: Object as PropType<User | null>,
    required: true,
  },
  availableProfiles: {
    type: Array as PropType<Profile[]>,
    default: () => [],
  },
  currentProfile: {
    type: Object,
    required: true,
  },
  isUpdatingProfile: {
    type: Boolean,
    default: false,
  },
  isFixed: {
    type: Boolean,
    required: true,
  },
  isOpen: { // Mobile open state
    type: Boolean,
    required: true,
  },
  isHovering: { // Is the parent drawer being hovered (when not fixed)?
    type: Boolean,
    required: true,
  },
  drawerRef: { // Needed for BSelect popover positioning
    type: Object as PropType<HTMLElement | null>,
    default: null,
  },
  // Functions passed down from the main drawer
  updateProfileFn: {
    type: Function as PropType<(profile) => void>,
    required: true,
  },
  toggleMobileMenuFn: {
    type: Function as PropType<() => void>,
    required: true,
  },
  toggleFixedFn: {
    type: Function as PropType<() => void>,
    required: true,
  },
  emitChangePasswordFn: {
     type: Function as PropType<() => void>,
     required: true,
  }
});

const imageError = ref(false);
const showSelect = ref(false);
const isDropDownOpen = ref(false);

// Determines if the text/details should be shown based on fixed/hover state
const showDetails = computed(() => props.isFixed || props.isHovering || props.isOpen);

const canSwitchProfile = computed(() => props.availableProfiles.length > 1);

const handleProfileUpdate = (profile) => {
  showSelect.value = false;
  if (profile._id !== props.currentProfile?._id) {
    props.updateProfileFn(profile); // Call the function passed from parent
  }
};

const dropDownItems = computed<DropdownItem[]>(() => [
  {
    display: 'Change password',
    click: props.emitChangePasswordFn, // Call passed function
    bgColor: 'base',
    _id: 'change-password',
    slotName: 'item-display-change-password',
  },
  {
    display: () => props.isFixed ? 'Collapse menu' : 'Fix menu',
    click: props.toggleFixedFn, // Call passed function
    bgColor: 'base',
    _id: 'fix-menu',
    slotName: 'item-display-fix-menu',
  },
  {
    display: 'Change language',
    bgColor: 'base',
    _id: 'change-language',
    slotName: 'item-display-change-language',
  },
  {
    display: 'Change color theme',
    bgColor: 'base',
    _id: 'change-color-theme',
    slotName: 'item-display-change-color-theme',
  },
]);

</script>

<template>
  <div v-if="user && currentProfile" class="flex items-center pl-2 h-16 md:h-24 relative justify-between md:justify-start shrink-0">
    <!-- Avatar -->
    <div
      class="rounded-full border-2 border-blue-500 mr-3 overflow-hidden md:h-10 md:w-10 w-8 h-8 aspect-square shrink-0"
      :title="`${user.firstname} ${user.lastname}`"
    >
      <img
        v-if="user.picture && !imageError"
        width="40" height="40" loading="lazy"
        :src="user.picture"
        :alt="`Avatar de ${user.firstname} ${user.lastname}`"
        class="object-cover h-full w-full"
        @error="() => imageError = true"
      >
      <DefaultProfile v-else class="h-full w-full" />
    </div>

    <!-- Profile Info & Actions (Show on Hover/Fixed/Mobile Open) -->
    <div
      v-show="showDetails"
      class="menu-item-show-hover flex-col px-2 gap-2 items-start w-full overflow-hidden"
      :class="{ 'md:opacity-100 md:flex': isFixed, 'md:opacity-0 md:hidden': !isFixed }"
    >
      <!-- Profile Selector -->
      <BSelect
        v-if="canSwitchProfile && showSelect"
        :popover-container="drawerRef"
        class="w-full shrink-0 min-w-20 grow flex-1"
        size="dense"
        :options="availableProfiles"
        display-prop="display"
        value-prop="_id"
        :model-value="currentProfile"
        @update:model-value="handleProfileUpdate"
      />
      <!-- Profile Display -->
      <BLabel
        v-else
        size="dense"
        bg-color="primary-op"
        :class="{ 'cursor-pointer hover:brightness-110': canSwitchProfile }"
        :title="canSwitchProfile ? 'Switch profile' : currentProfile.display"
        @click="showSelect = canSwitchProfile ? true : false"
      >
        <template v-if="!isUpdatingProfile">
          {{ currentProfile.display }}
        </template>
        <Loading v-else size="sm"/>
      </BLabel>

      <!-- Desktop Settings Dropdown -->
      <div class="hidden md:flex gap-2 text-xl items-center">
        <BDropdown
            position="right-start"
            :items="dropDownItems"
            v-model:is-open="isDropDownOpen"
            :disabled="isUpdatingProfile"
        >
          <template #triggerContent>
            <BButton bg="base" size="dense" :disabled="isUpdatingProfile">
              <SolarSettingsBroken /> Account settings
            </BButton>
          </template>

          <!-- Slot implementations for dropdown items -->
          <template #item-display-change-language>
             <span class="flex gap-2 items-center justify-between w-full">
                <LocalSwitch class="w-full">
                  <template #prepend-text>
                    Change language
                  </template>
                </LocalSwitch>
              </span>
          </template>

          <template #item-display-change-color-theme>
            <ThemeSwitcher>
              <template #default="{ isDark: isDarkTheme, toggle }">
                <div class="flex gap-2 items-center justify-between w-full" @click="toggle">
                  <p>Change color theme</p>
                  <span class="tracking-wide cursor-pointer text-xl">
                    <PhMoonStarsDuotone v-if="isDarkTheme.value" />
                    <PhSunDuotone v-else />
                  </span>
                </div>
              </template>
            </ThemeSwitcher>
          </template>

          <template #item-display-change-password>
             <p class="flex justify-between items-center gap-2 w-full">
               Change password <SolarKeyBroken />
             </p>
          </template>

          <template #item-display-fix-menu>
            <p class="flex justify-between items-center gap-2 w-full">
              <template v-if="isFixed">
                Collapse menu <MaterialSymbolsLockOpenOutlineRounded />
              </template>
              <template v-else>
                Fix menu <MaterialSymbolsLockOutline />
              </template>
            </p>
          </template>
        </BDropdown>
      </div>
    </div>

    <!-- Mobile Burger -->
    <Burger class="ml-auto mr-4 md:hidden" :is-open="isOpen" @toggle="toggleMobileMenuFn" />
  </div>
</template>

<style scoped>
/* Styles for showing details on hover/fixed state */
.drawer-container:hover .menu-item-show-hover,
.drawer-container-fixed .menu-item-show-hover {
  opacity: 1 !important;
  display: flex !important;
  transition: opacity 0.3s ease-in-out;
}
/* Ensure initial state is hidden correctly on desktop when not fixed */
@media (min-width: 768px) {
  .menu-item-show-hover:not(.md\:flex) {
     opacity: 0;
     display: none;
   }
}
</style>
